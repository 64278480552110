import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { CountriesService } from './services';
import { CountriesState } from './store';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([CountriesState])],
  providers: [CountriesService],
})
export class CountriesModule {}
