import { of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { Query, QueryPaging } from '@supy/common';

import { Country } from '../../core';
import { CountriesService } from '../../services';
import { GetCountries } from '../actions';

interface CountriesStoreState {
  readonly countries: Country[];
}

const COUNTRIES_STATE_TOKEN = new StateToken<CountriesStoreState>('countries');

@State<CountriesStoreState>({
  name: COUNTRIES_STATE_TOKEN,
})
@Injectable()
export class CountriesState implements NgxsOnInit {
  constructor(private readonly countriesService: CountriesService) {}

  @Selector()
  static countries(state: CountriesStoreState) {
    return state.countries;
  }

  @Selector()
  static getCountry(state: CountriesStoreState) {
    const countries = new Map(state.countries?.map(country => [country.id, country]));

    return (id: string) => countries.get(id);
  }

  ngxsOnInit(ctx: StateContext<CountriesStoreState>): void {
    ctx.dispatch(new GetCountries());
  }

  @Action(GetCountries)
  getCountries(ctx: StateContext<CountriesStoreState>, { forceFetch }: GetCountries) {
    const countries = ctx.getState().countries;
    const query = new Query<Country>({
      filtering: [],
      paging: QueryPaging.NoLimit,
    });

    return countries && !forceFetch
      ? of(countries)
      : this.countriesService.getCountries(query).pipe(tap(({ data }) => ctx.patchState({ countries: data })));
  }
}
